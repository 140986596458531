import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useCallback } from 'react';
import { BlogPreview } from '../../../types/blog';

interface UseArticlesProps
  extends Omit<
    UseQueryOptions<BlogPreview[], Error, BlogPreview[]>,
    'queryFn' | 'refetchOnWindowFocus' | 'throwOnError' | 'retry'
  > {
  limit: number;
}

export function useArticles({ limit = 1, ...queryOptions }: UseArticlesProps) {
  const fetchArticles = useCallback(async () => {
    const queryParams = new URLSearchParams({
      page: '1',
      per_page: limit.toString(),
    });

    const response = await fetch(`/api/articles?${queryParams}`);

    if (!response.ok) {
      throw new Error('There was a problem fetching the articles.');
    }

    const { blogPosts }: { blogPosts: BlogPreview[] } = await response.json();

    return blogPosts;
  }, [limit]);

  const result = useQuery({
    queryFn: fetchArticles,
    refetchOnWindowFocus: false,
    throwOnError: false,
    retry: false,
    ...queryOptions,
  });

  return result;
}
