import { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '@/hooks/useAuth';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { SearchBar } from '@/components/molecules/SearchBar/SearchBar';
import { useZipContext } from '@/context/ZipContext';
import { useRecentSearch } from '@/hooks/useRecentSearch';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { handleFilteredSearch } from '@/lib/searchFilters';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { LocationPopOutModal } from '../LocationPopOutModal/LocationPopOutModal';

export type HomeTemplateProps = {
  makes: string[];
  models: { [key: string]: string[] };
};

export const HomePageSearchBar: FC<HomeTemplateProps> = (props) => {
  const { makes, models } = props;
  const [input, setInput] = useState<string>('');
  const { handleSaveRecentSearch, handleGetRecentSearch } = useRecentSearch();

  const { userAddress, customZip, customAddress } = useZipContext();
  const { postal, city, state, country } = userAddress || {};
  const { user } = useAuth();

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const location =
    city !== undefined && state !== undefined ? `${city}, ${state}` : postal;

  const { data: recentSearch = [] } = useQuery({
    queryKey: ['fetchRecentSearch', user],
    queryFn: handleGetRecentSearch,
    throwOnError: false,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="flex w-full flex-col gap-m rounded-lg bg-white p-m ml:h-[72px] ml:flex-row">
        <div className="inline-flex h-[48px] w-full">
          <SearchBar
            input={input}
            setInput={setInput}
            models={models}
            makes={makes}
            recentSearch={recentSearch}
          />
        </div>

        <div className="flex h-[48px] w-full ml:h-full ml:w-1/5">
          <Button
            aria-label="Search EVs"
            variant={ButtonVariants.Tertiary}
            analyticsEvent={{
              ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
              pageTarget: 'search',
            }}
            onClick={async () => {
              await handleSaveRecentSearch(input);
              handleFilteredSearch({
                input,
                zip: customZip || postal,
                country,
              });
            }}
          >
            Search EVs
          </Button>
        </div>
      </div>

      <div className="flex w-full justify-between pt-l">
        {city && state && country && (
          <div
            className="fit-content flex cursor-pointer items-center"
            onClick={() => setLocationModalOpen(true)}
          >
            <MapPinIcon
              stroke="#FFFFFF"
              className="pr-s"
              width={24}
              height={24}
            />
            <span className="text-body1Medium text-neutral-0">
              {customAddress || customZip || location}
            </span>
            <LocationPopOutModal
              open={locationModalOpen}
              initialZipCode={customZip || postal}
              shouldStayOrigin={true}
              persistZip={true}
              close={() => {
                setLocationModalOpen(false);
              }}
            />
          </div>
        )}
        {/* TODO : link to car chooser quiz doesnt exist yet */}
        {/* <div className="fit-content mr-[15px] flex cursor-pointer items-center justify-end">
          <span className="pr-s text-body1Medium text-neutral-0">
            Still not sure? We&apos;ll help you choose
          </span>
          <span className="relative">
            <Icon
              className="absolute right-[-15px] top-1/2 translate-y-[-45%] opacity-100"
              iconId={IconIds.ChevronRight}
              strokeColor={'#FFFFFF'}
            />
          </span>
        </div> */}
      </div>
    </>
  );
};
