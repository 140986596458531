import { TextButtonVariants } from '@/components/atoms/TextButton/TextButton';
import TextButtonLink from '@/components/atoms/TextButtonLink/TextButtonLink';
import Carousel from '@/components/molecules/Carousel';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import useWindowSize from '@/hooks/useWindowSize';
import { VehicleListingType } from '@/lib/schema/inventory/types';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { ProductListingLink } from '../ProductListingLink/ProductListingLink';

interface CarouselVehicleCard extends VehicleListingType {
  isSaved: boolean;
  contactedDealer: boolean;
}

interface VehicleCarouselProps {
  vehicles: CarouselVehicleCard[];
  title: string;
  showEvSavingsToggle?: boolean;
  estFuelSavingsByType: Record<
    string,
    {
      savings: number;
    }
  > | null;
  viewAllLink?: string;
}

export const VehicleCarousel: FC<VehicleCarouselProps> = ({
  vehicles,
  title,
  showEvSavingsToggle = true,
  estFuelSavingsByType,
  viewAllLink = '/search',
}) => {
  const { isDesktop, isML, isM, isMobile } = useWindowSize();

  let imagesLength = vehicles.length;

  if (isDesktop) {
    imagesLength = vehicles.slice(0, 5).length;
  }
  if (isML) {
    imagesLength = Math.ceil(vehicles.length / 3);
  }
  if (isM) {
    imagesLength = Math.ceil(vehicles.length / 2);
  }

  if (vehicles.length > 0) {
    return (
      <div className="flex flex-col pt-[40px]">
        <div className="mb-xl flex flex-col items-center justify-between gap-m m:flex-row">
          <div className="self-start text-h3Regular text-neutral-900">
            {title}
          </div>
          <div className="flex w-full items-center justify-between gap-[40px] m:w-auto">
            {showEvSavingsToggle && (
              <div className="flex justify-between gap-s">
                <div className="text-body1Light text-neutral-900">
                  EV Savings
                </div>
                <EVSavingsToggle size={'small'} />
              </div>
            )}
            <TextButtonLink
              aria-label="View More Evs"
              variant={TextButtonVariants.Default}
              href={viewAllLink}
              icon={{
                position: 'right',
                icon: <ChevronRightIcon className="h-xl w-xl" />,
              }}
            >
              View all
            </TextButtonLink>
          </div>
        </div>
        <div>
          <Carousel
            className="w-full gap-l"
            showDirectionButtons={true}
            navigationPosition="bottom"
            imagesLength={imagesLength}
            infinite={true}
            isShowingDots={true}
          >
            {vehicles.map(({ isSaved, contactedDealer, ...vehicle }) => {
              return (
                <div
                  key={`popular-make-carousel-${vehicle.listingId}`}
                  className={`w-full pr-m m:w-1/2 ml:w-1/3 l:w-[25%]`}
                >
                  <ProductListingLink
                    showNewCheckAvailabilityButton={true}
                    product={vehicle}
                    isSaved={isSaved}
                    isColumnLayout={true}
                    isListedPrice={true}
                    isMobile={isMobile}
                    contactedDealer={contactedDealer}
                    estFuelSavingsByType={estFuelSavingsByType}
                  />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
  return null;
};
