import Link from '@/components/atoms/Link/Link';
import ClampedText from '@/components/molecules/ClampedText';
import { DynamicImageWrapper } from '@/components/molecules/DynamicImageWrapper/DynamicImageWrapper';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import { BlogPreview } from '@/types/blog';
import clsx from 'clsx';
import React from 'react';

export interface BlogListingLinkProps {
  layout?: 'row' | 'column';
  blog: BlogPreview;
  showExcerpt?: boolean;
  titleVariant?: 'large' | 'small';
}

export const BlogListingLink: React.FC<BlogListingLinkProps> = ({
  blog,
  layout = 'column',
  showExcerpt = false,
  titleVariant = 'small',
}) => {
  return (
    <Link
      href={`/news/${blog.slug}`}
      analyticsEvent={{
        ...GOOGLE_TRACK_INFO.blogArticlePageButton,
        pageTarget: `/news/${blog.slug}`,
      }}
    >
      <div
        className={clsx('flex h-full w-full rounded-small bg-white', {
          'flex-col': layout === 'column',
          'flex-row': layout === 'row',
        })}
      >
        <div
          className={clsx('flex flex-1', {
            'flex-col justify-center': layout === 'row',
          })}
        >
          <DynamicImageWrapper
            className="!relative !h-auto rounded-t-sm"
            src={blog?.image ?? undefined}
            alt="background image"
            ratio={9 / 16}
          />
        </div>
        <section className="flex flex-1 flex-col items-start justify-start p-l">
          <ClampedText className="mb-xs text-body2Light text-neutral-800">
            {blog?.categories?.join(', ').toUpperCase()}
          </ClampedText>
          {titleVariant === 'large' ? (
            <h4
              className={clsx('text-h4SemiBold text-neutral-900', {
                'mb-s': showExcerpt,
              })}
            >
              {blog.title}
            </h4>
          ) : (
            <p className="text-body1Medium text-neutral-900">{blog.title}</p>
          )}
          {showExcerpt && (
            <div>
              <span className="line-clamp-2 text-body1Light text-neutral-900">
                {blog.excerpt}
              </span>
            </div>
          )}
        </section>
      </div>
    </Link>
  );
};
