export type SearchType = 'SEO' | 'Search';

// corresponing meta tags for the SEO typs are under inventoryPage
export const SEOMetaTags = {
  homePage: {
    // ev.com
    title: 'EV | New & Used Electric Cars for Sale | EV.com',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: `EV for${'\u00A0'}All`,
    h2Tag: 'Shop electric cars from our trusted partners',
    imageAlt: 'ev.com',
    keywords: 'electric cars for sale',
  },
  buildPage: {
    title: 'Build your $MAKE EV | EV.com',
    description:
      'Build your $MAKE EV on EV.com. Customize your $MAKE EV to fit your needs and preferences with our easy-to-use tools.',
    h1Tag: 'Build your $MAKE EV',
    imageAlt: 'build your $MAKE ev',
    keywords: 'build $MAKE ev',
  },
  buildModelPage: {
    title: 'Build your $YEAR $MAKE $MODEL EV | EV.com',
    description:
      'Build your $MAKE EV on EV.com. Customize your $YEAR $MAKE $MODEL EV to fit your needs and preferences with our easy-to-use tools.',
    h1Tag: 'Choose Your $YEAR $MAKE $MODEL EV Trim',
    imageAlt: 'build your $YEAR $MAKE $MODEL ev',
    keywords: 'build $YEAR $MAKE $MODEL ev',
  },
  // ev.com/search
  searchPage: {
    title: 'EV | Electric Cars for Sale Near Me | EV.com',
    description:
      'Discover a variety of electric cars available for sale near you on EV.com. Use our search tool to find the best electric vehicles in your area.',
    h1Tag: 'EV | Electric Cars For Sale Near Me',
    imageAlt: 'ev cars for sale',
    keywords: 'electric cars for sale near me',
  },
  // seo search pages
  // ev.com/$DEALER/$CONDITION/$YEAR/$MAKE/$MODEL/$LOCATION/$BODYTYPE/$FUELTYPE/$PRICE_END
  seoPage: {
    title:
      '$CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE For Sale $PRICE_END $LOCATION $DEALER | EV.com',
    description:
      'Discover a wide range of $CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE for sale $PRICE_END $LOCATION $DEALER on EV.com. Find your perfect $CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE from top brands, compare prices, read reviews, and get the best deals today!',
    h1Tag:
      '$CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE For Sale $PRICE_END $LOCATION $DEALER',
    h2Tag: 'Shop EVs from our trusted partners',
    imageAlt:
      '$CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE for sale $PRICE_END $LOCATION $DEALER',
    keywords:
      '$CONDITION $YEAR $MAKE $MODEL $FUELTYPE $BODYTYPE for sale $PRICE_END $LOCATION $DEALER',
  },
  comparePage: {
    title: 'Compare EV | Electric Vehicle | EV.com',
    description:
      'Effortlessly compare electric vehicles on EV.com to find the perfect match for your driving needs. Explore specs, range, and pricing today.',
    imageAlt: 'compare evs',
    h1Tag: 'Compare Electric Vehicles',
    keywords: 'compare electric vehicles',
  },
  blogPage: {
    title: 'EV News | Electric Cars Buying & Selling News | EV.com',
    description:
      'Stay ahead with the latest EV News on buying & selling electric cars at EV.com. Get insights, trends, and updates to make informed decisions.',
    h1Tag: 'EV News',
    keywords: 'ev news',
  },
  partnerPage: {
    title: 'Partner with EV.com | EV.com',
    description:
      "Let's accelerate the transition towards zero-emission mobility together. Sell more EVs when you partner with us.",
    h1Tag: 'Partner with EV.com',
    keywords: 'partner with ev.com',
  },
  errorpage: {
    title:
      'Page Not Found | Shop new & used electric cars from trusted partners on EV.com.',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: `Sorry we couldn't find the page you were looking for.`,
    h2Tag: 'To find your way to your next EV, try one of the option below.',
    keywords: 'page not found',
  },
  faqPage: {
    title:
      'Frequently Asked Questions | Shop new & used electric cars from trusted partners on EV.com.',
    description:
      'EV for Sale | Shop new & used electric cars from trusted partners on EV.com. Save money, drive electric, and help the planet. Discover your perfect EV today!',
    h1Tag: 'Frequently Asked Questions',
    h2Tag: 'Everything you need to know about EV.com',
    keywords: 'faq',
  },
  dealerPage: {
    title: '$DEALER electric cars for sale | EV.com',
    description:
      'Discover $DEALER electric cars for sale on EV.com. Get the best deals and newest models from your local dealer.',
    h1Tag: '$DEALER Electric Cars for Sale',
    keywords: 'electric cars',
  },
  loginPage: {
    title: 'Login | EV.com',
    description:
      'Login to your EV.com account to access personalized features and manage your electric vehicle preferences.',
    h1Tag: 'Login to your account',
    keywords: 'login',
  },
  accountSettings: {
    title: 'My EVs | EV.com',
    description:
      'Manage your account on EV.com. Customize your experience and stay updated on the latest electric vehicle news.',
    h1Tag: 'My EVs',
    keywords: 'settings account',
  },
  generalModelSeoTemplate: {
    title:
      'Explore All Electric Vehicle Makes & Models | EV.com - Your Ultimate EV Resource',
    description:
      'Discover all electric vehicle makes and models on EV.com. Get the latest updates, reviews, and news to help you choose the perfect EV for your lifestyle and needs.',
    h1Tag: '',
    keywords: '',
  },
};

export type SeoTagType = keyof typeof SEOMetaTags;
export interface SeoTagsParserProps {
  tagType: SeoTagType;
  makes?: string[];
  models?: string[];
  years?: string[];
  bodyTypes?: string[];
  locationTitle?: string;
  fuelType?: string;
  condition?: 'New' | 'Used';
  dealer?: string;
  priceEnd?: string;
}
export function getSeoTagParser({
  tagType,
  makes,
  models,
  years,
  bodyTypes,
  locationTitle,
  fuelType,
  condition,
  dealer,
  priceEnd,
}: SeoTagsParserProps) {
  let makesString = '';
  if (makes && makes.length > 0) {
    makesString =
      makes.length === 1
        ? `${makes[0]}`
        : `${makes.slice(0, -1).join(', ')} and ${makes[makes.length - 1]}`;
  }
  let modelsString = '';
  if (models && models.length > 0) {
    modelsString =
      models.length === 1
        ? `${models[0]}`
        : `${models.slice(0, -1).join(', ')} and ${models[models.length - 1]}`;
  }

  let bodyTypesString = 'Cars';
  if (bodyTypes && bodyTypes.length > 0) {
    bodyTypesString =
      bodyTypes.length === 1
        ? `${bodyTypes[0]}s`
        : `${bodyTypes.slice(0, -1).join(', ')} and ${bodyTypes[bodyTypes.length - 1]}`;
  }

  let fuelTypeString = 'Electric';
  if (fuelType === 'Hybrid') {
    fuelTypeString = `Plug-in Hybrid`;
  }

  const yearsString = years && years.length > 0 ? `${years.join(', ')}` : '';
  const locationString = locationTitle ? `In ${locationTitle}` : 'Near Me';
  const conditionString = condition ? `${condition} ` : '';
  const dealerString = dealer ? `- ${dealer}` : '';
  const priceEndString = priceEnd ? `Under $${priceEnd}` : '';

  const seoMetaObjH1 = SEOMetaTags[tagType].h1Tag
    .replaceAll('$FUELTYPE', fuelTypeString)
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makesString)
    .replaceAll('$MODEL', modelsString)
    .replaceAll('$BODYTYPE', bodyTypesString)
    .replaceAll('$YEAR', yearsString)
    .replaceAll('$DEALER', dealerString)
    .replaceAll('$PRICE_END', priceEndString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaObjTitle = SEOMetaTags[tagType].title
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$FUELTYPE', fuelTypeString)
    .replaceAll('$MAKE', makesString)
    .replaceAll('$MODEL', modelsString)
    .replaceAll('$BODYTYPE', bodyTypesString)
    .replaceAll('$YEAR', yearsString)
    .replaceAll('$DEALER', dealerString)
    .replaceAll('$PRICE_END', priceEndString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaObjDescription = SEOMetaTags[tagType].description
    .replaceAll('$FUELTYPE', fuelTypeString)
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makesString)
    .replaceAll('$MODEL', modelsString)
    .replaceAll('$BODYTYPE', bodyTypesString)
    .replaceAll('$YEAR', yearsString)
    .replaceAll('$DEALER', dealerString)
    .replaceAll('$PRICE_END', priceEndString)
    .replace(/\s+/g, ' ')
    .trim();
  const seoMetaKeywords = SEOMetaTags[tagType].keywords
    .replaceAll('$FUELTYPE', fuelTypeString)
    .replaceAll('$CONDITION', conditionString)
    .replaceAll('$LOCATION', locationString)
    .replaceAll('$MAKE', makesString)
    .replaceAll('$MODEL', modelsString)
    .replaceAll('$BODYTYPE', bodyTypesString)
    .replaceAll('$YEAR', yearsString)
    .replaceAll('$DEALER', dealerString)
    .replaceAll('$PRICE_END', priceEndString)
    .replace(/\s+/g, ' ')
    .trim()
    .toLowerCase();

  return {
    seoMetaObjTitle,
    seoMetaObjDescription,
    seoMetaObjH1,
    seoMetaKeywords,
  };
}
