import { IconIds } from '@/components/atoms/legacy/Icon';
import { ResponsiveMargin } from '@/components/atoms/legacy/ResponsiveMargin/ResponsiveMargin';
import { AnnouncementModal } from '@/components/organisms/AnnouncementModal/AnnouncementModal';
import HeroSection from '@/components/organisms/HeroSection/HeroSection';
import { HomePageCalculatorSection } from '@/components/organisms/HomePageCalculatorSection/HomePageCalculatorSection';
import { HomePageCompareCarousel } from '@/components/organisms/HomePageCompareCarousel//HomePageCompareCarousel';
import { HomePageShopNowCard } from '@/components/organisms/HomePageShopNowCard/HomePageShopNowCard';
import { HomePageShopType } from '@/components/organisms/HomePageShopType/HomePageShopType';
import { LandingPageBanner } from '@/components/organisms/LandingPageBanner/LandingPageBanner';
import LandingPageContact from '@/components/organisms/LandingPageContact';
import { NewHeroSection } from '@/components/organisms/NewHeroSection/NewHeroSection';
import { PopularMakesCarousel } from '@/components/organisms/PopularMakesCarousel/PopularMakesCarousel';
import { TrimStyle } from '@/lib/schema/build/types';
import { FC, useState } from 'react';
import { HomePageRecentArticles } from '../../organisms/HomePageRecentArticles';

export interface HomeTemplateProps {
  longestRange: TrimStyle[];
  mostAffordable: TrimStyle[];
  bestForFamily: TrimStyle[];
  showNewHero: boolean;
  makes: string[];
  models: { [key: string]: string[] };
  estFuelSavingsByType: Record<string, { savings: number }> | null;
}

export const HomeTemplate: FC<HomeTemplateProps> = (props) => {
  const [announcementModalOpen, setAnnouncementModalOpen] = useState(false);
  const showHertzBanner = false; // disabled for now

  return (
    <div className="relative w-full">
      <section className="p-0">
        {props.showNewHero ? (
          <NewHeroSection makes={props.makes} models={props.models} />
        ) : (
          <HeroSection />
        )}
      </section>

      {showHertzBanner && (
        <div className="absolute top-0 w-full">
          <LandingPageBanner
            iconId={IconIds.HertzLogo}
            text="New inventory"
            subtext="We've partnered with Hertz Car Sales, adding thousands of EVs for you to explore."
            mobileText="We've partnered with Hertz Car Sales."
            cta="See Details"
            onClickCta={() => setAnnouncementModalOpen(true)}
          />
        </div>
      )}

      <ResponsiveMargin variant="template">
        <PopularMakesCarousel
          estFuelSavingsByType={props.estFuelSavingsByType}
          sectionHeader="Shop by Popularity"
        />
      </ResponsiveMargin>

      <ResponsiveMargin variant="template">
        <HomePageCompareCarousel />
      </ResponsiveMargin>

      <div className="pt-xl">
        <HomePageCalculatorSection />
      </div>

      <ResponsiveMargin variant="template">
        <HomePageShopNowCard vehicles={props.longestRange} />
      </ResponsiveMargin>

      <ResponsiveMargin variant="template">
        <HomePageShopType makes={props.makes} />
      </ResponsiveMargin>

      <HomePageRecentArticles />

      {process.env.NEXT_PUBLIC_SHOW_HOMEPAGE_CONNECT_CARD === 'true' && (
        // MVB-1442 : Hide until the Marketing Team has a plan to produce newsletter content.
        <section className="px-[20px] py-[40px] md:px-[40px] lg:px-[100px] lg:py-[150px]">
          <LandingPageContact />
        </section>
      )}
      <AnnouncementModal
        image={{
          src: '/images/hertz-banner-small.png',
          width: 560,
          height: 336,
          alt: 'Hertz new inventory',
        }}
        open={announcementModalOpen}
        onClose={() => setAnnouncementModalOpen(false)}
        text="New inventory from Hertz"
        subtext="We've partnered with Hertz Car Sales, adding thousands of EVs for you to explore. Take advantage of Hertz's no-haggle pricing and 7-day/250-mile Buy Back Guarantee."
      />
    </div>
  );
};
