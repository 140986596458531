import { useZipContext } from '@/context/ZipContext';
import { parseListingVehicle } from '@/lib/parseListingVehicle';
import { VehicleListingModel } from '@/lib/schema/inventory/types';
import { useQuery } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import { VehicleCarousel } from '../VehicleCarousel';

export interface PopularMakesCarouselProps {
  estFuelSavingsByType: Record<string, { savings: number }> | null;
  sectionHeader: string;
}

export const PopularMakesCarousel: FC<PopularMakesCarouselProps> = ({
  estFuelSavingsByType,
  sectionHeader,
}) => {
  const { userAddress, customZip } = useZipContext();

  const fetchData = useCallback(async () => {
    let zip;
    if (customZip) {
      zip = customZip;
    } else if (userAddress && userAddress.country === 'US') {
      zip = userAddress.postal;
    }

    try {
      const response = await fetch('/api/inventory/listings/popular', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postal: zip,
          limit: 20,
          radius_km: 322,
          sort: 'rankedscore_desc',
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const { content: matchesRaw }: { content: VehicleListingModel[] } =
          data;
        const matches = matchesRaw.map((product: VehicleListingModel) =>
          parseListingVehicle(product)
        );

        return matches;
      }
    } catch (error) {
      return [];
    }
    return [];
  }, [customZip, userAddress]);

  const { data: vehicleList = [] } = useQuery({
    queryKey: ['fetchPopularListing', userAddress, customZip],
    queryFn: fetchData,
    throwOnError: false,
    refetchOnWindowFocus: false,
  });

  return (
    <VehicleCarousel
      vehicles={vehicleList.map((v) => ({
        ...v,
        isSaved: false,
        contactedDealer: false,
      }))}
      estFuelSavingsByType={estFuelSavingsByType}
      title={sectionHeader}
    />
  );
};
