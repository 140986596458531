import NextLink, { LinkProps as NextLinkProp } from 'next/link';
import React, { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react';
import TextButton, {
  TextButtonProps,
  TextButtonVariants,
} from '../TextButton/TextButton';

export interface ButtonLinkProps
  extends Omit<TextButtonProps, 'onClick' | 'onMouseEnter'>,
    NextLinkProp {
  target?: HTMLAttributeAnchorTarget;
  onClick?: () => void;
  onMouseEnter?: () => void;
}

const ButtonLink: React.FC<PropsWithChildren<ButtonLinkProps>> = ({
  href,
  variant = TextButtonVariants.Default,
  children,
  target,
  ...props
}) => {
  return (
    <NextLink aria-label={`link to ${href}`} href={href} target={target}>
      <TextButton {...props} variant={variant}>
        {children}
      </TextButton>
    </NextLink>
  );
};

export default ButtonLink;
